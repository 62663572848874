export const BEACONS = '[BEACONS]';

// action types
export const FETCH_BEACONS = `${BEACONS} FETCH`;
export const DELETE_BEACON = `${BEACONS} DELETE`;
export const FETCH_LIFECODES = `${BEACONS} FETCH LIFECODES`;

export const SET_BEACONS = `${BEACONS} SET`;
export const CREATE_BEACON = `${BEACONS} CREATE`;
export const UPDATE_BEACON = `${BEACONS} UPDATE`;
export const SHOW_BEACON = `${BEACONS} SHOW`;

export const fetchBeacons = () => ({
  type: FETCH_BEACONS,
  payload: null,
  function: 'getBeacons',
});

export const fetchBlueToothBeacons = () => ({
  type: FETCH_BEACONS,
  payload: null,
  function: 'getDashboardBlueToothBeacons',
});

export const fetchLifeCodes = (query) => ({
  type: FETCH_BEACONS,
  payload: query,
  function: 'getLifeCodes',
});

export const deleteBeacon = ({ query }) => ({
  type: DELETE_BEACON,
  payload: query,
  function: 'deleteBeacons',
});

export const createBeacon = ({ query }) => ({
  type: CREATE_BEACON,
  payload: query,
  function: 'createBeacon',
});

export const updateBeacon = ({ query }) => ({
  type: UPDATE_BEACON,
  index: query.index,
  payload: query.data,
});

export const showBeacon = ({ query }) => ({
  type: SHOW_BEACON,
  index: query.index,
  payload: query.data,
});

export const setBeacons = ({ beacons }) => ({
  type: SET_BEACONS,
  payload: beacons,
});
