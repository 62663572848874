import React from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  Grid,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  section: {
    padding: '40px 0',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f5f5f5',
  },
  media: {
    flex: '0 0 100px',
    borderRadius: '8px',
    objectFit: 'contain',
  },
  content: {
    flex: '2',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#333',
  },
  description: {
    color: '#666',
    lineHeight: '1.6',
  },
  header: {
    textAlign: 'center',
    marginBottom: '40px',
  },
  headerTitle: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  headerDescription: {
    fontSize: '1.2rem',
    color: '#666',
  },
}));

const Retail = () => {
  const classes = useStyles();

  return (
    <Container>
      {/* Header Section */}
      <Box className={classes.header}>
        <Typography variant="h1">
          Boost Your Retail Business with LifeTagger
        </Typography>
        <Typography variant="subtitle1">
          Enhance customer interaction with product details, special offers, and
          loyalty programs using QR codes and NFC technology.
        </Typography>
      </Box>

      {/* Call to Action */}
      <Box
        sx={{
          textAlign: 'center',
          backgroundColor: '#f5f5f5',
          padding: '40px 20px',
          marginTop: '40px',
          borderRadius: '8px',
        }}
      >
        <Typography
          variant="button"
          component="a"
          href="/regspecial"
          sx={{
            backgroundColor: '#1976d2',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '8px',
            textDecoration: 'none',
            display: 'inline-block',
            marginTop: '10px',
          }}
        >
          Get Started Today
        </Typography>
      </Box>

      {/* Who, What, Where, When, How Section */}
      <Grid container spacing={4} className={classes.section}>
        {/* Who */}
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image="/images/retailmanager.jpg" // Replace with actual image
                  alt="Who Image"
                  className={classes.media}
                />
              </Grid>
              <Grid item xs={5}>
                <CardContent className={classes.content}>
                  <Typography variant="h2">Who</Typography>
                  <Typography variant="subtitle1">
                    Retail store owners, managers, and customers looking for
                    enhanced shopping experiences.
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* What */}
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item xs={5}>
                <CardContent className={classes.content}>
                  <Typography variant="h2">What</Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.description}
                  >
                    A platform to deliver product information, special offers,
                    and loyalty rewards through LifeCodes and NFC technology.
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image="/images/loyaltyretail.jpg" // Replace with actual image
                  alt="What Image"
                  className={classes.media}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* Where */}
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image="/images/shoppingmall.jpg" // Replace with actual image
                  alt="Where Image"
                  className={classes.media}
                />
              </Grid>
              <Grid item xs={5}>
                <CardContent className={classes.content}>
                  <Typography variant="h2">Where</Typography>
                  <Typography variant="subtitle1">
                    Retail stores, shopping malls, and online platforms where
                    customer engagement and satisfaction are key.
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* When */}
        <Grid container item xs={12}>
          <Card className={classes.card}>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item xs={5}>
                <CardContent className={classes.content}>
                  <Typography variant="h2">When</Typography>
                  <Typography variant="subtitle1">
                    During shopping hours, promotional events, and throughout
                    the year, LifeTagger keeps your customers engaged and
                    informed.
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image="/images/storehours.jpg" // Replace with actual image
                  alt="When Image"
                  className={classes.media}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* How */}
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image="/images/retail.jpg" // Replace with actual image
                  alt="How Image"
                  className={classes.media}
                />
              </Grid>
              <Grid item xs={5}>
                <CardContent className={classes.content}>
                  <Typography variant="h2">How</Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.description}
                  >
                    Use LifeCodes to share product details, gather customer
                    feedback, and provide dynamic, personalized shopping
                    experiences.
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {/* Call to Action */}
      <Box
        sx={{
          textAlign: 'center',
          backgroundColor: '#f5f5f5',
          padding: '40px 20px',
          marginTop: '40px',
          borderRadius: '8px',
        }}
      >
        <Typography
          variant="button"
          component="a"
          href="/regspecial"
          sx={{
            backgroundColor: '#1976d2',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '8px',
            textDecoration: 'none',
            display: 'inline-block',
            marginTop: '10px',
          }}
        >
          Get Started Today
        </Typography>
      </Box>
    </Container>
  );
};

export default Retail;
