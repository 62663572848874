import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../products/products.actions.js';
import { Grid, Button } from '@mui/material';
import {
  FormControl,
  RadioGroup,
  Paper,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Show } from './products.show';

export function ProductsIndex(props) {
  const [isYearly, setIsYearly] = React.useState(false);
  const backgroundColors = ['#E6E2F7', '#F9F0E1', '#BFE2EB'];

  const dispatch = useDispatch();
  const [accountPlan, setAccountPlan] = React.useState(
    'price_1JxGjpG7JJ7XmqTHBo3Y2o9t'
  );

  const yearlyLabel = isYearly
    ? 'Yearly(Yay!!! you Get Two Months Free)'
    : 'Yearly(Toggle to get 2 Months Free)';
  let products = useSelector((state) => state.products.data);

  useEffect(() => {
    dispatch(getProducts({}, getProducts));
  }, [dispatch]);

  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleSubmit(accountPlan);
  };

  const handlePlanChange = (event) => {
    setAccountPlan(event.target.value);
  };

  return (
    <Grid container>
      <Grid item sm={12}>
        <Paper
          sx={{
            width: '80%',
            padding: '32px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid item justifySelf={'center'} xs={6}>
            <Typography sx={{ margin: '12px' }} variant={'h4'}>
              Get Started by choosing a plan
            </Typography>
          </Grid>
          <FormControlLabel
            control={
              <Switch
                checked={isYearly}
                onChange={() => setIsYearly(!isYearly)}
              />
            }
            label={yearlyLabel}
          />
        </Paper>
      </Grid>
      <form>
        <Grid item sm={12}>
          <Grid item sm={12}>
            <FormControl varient="outlined">
              <div>
                <RadioGroup
                  aria-label="Account Plan"
                  name="accountPlan"
                  onChange={(value) => handlePlanChange(value.target.value)}
                >
                  <Grid container spacing={2}>
                    {products.map(
                      (product, key) =>
                        product.active &&
                        product.id != 'prod_N6Q3ALnPyziPpP' && (
                          <Grid item xs={3} xm={3} key={key}>
                            {key !== 0 ? (
                              <Show
                                product={product}
                                setPlan={handlePlanChange}
                                isYearly={isYearly}
                                backgroundColor={
                                  backgroundColors[
                                    key % backgroundColors.length
                                  ]
                                }
                              />
                            ) : (
                              <Show
                                product={product}
                                bestSeller={true}
                                setPlan={handlePlanChange}
                                isYearly={isYearly}
                                backgroundColor={
                                  backgroundColors[
                                    key % backgroundColors.length
                                  ]
                                }
                              />
                            )}
                          </Grid>
                        )
                    )}
                  </Grid>
                </RadioGroup>
              </div>
            </FormControl>
          </Grid>
        </Grid>
      </form>
      <Grid item sm={12}>
        <Button
          type="submit"
          className="btn btn-primary"
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
