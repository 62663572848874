import React from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  Grid,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  section: {
    padding: '40px 0',
  },
  card: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '20px',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f5f5f5',
  },
  media: {
    flex: '0 0 100px',
    borderRadius: '8px',

    objectFit: 'contain',
  },
  content: {
    flex: '2',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '10px',
    color: '#333',
  },
  description: {
    color: '#666',
    lineHeight: '1.6',
  },
  header: {
    textAlign: 'center',
    marginBottom: '40px',
  },
  headerTitle: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  headerDescription: {
    fontSize: '1.2rem',
    color: '#666',
  },
}));

const ConferenceAndEvents = () => {
  const classes = useStyles();

  return (
    <Container>
      {/* Header Section */}
      <Box className={classes.header}>
        <Typography variant="h1">
          Elevate Your Events with LifeTagger
        </Typography>
        <Typography variant="subtitle1">
          Empower your attendees with real-time, personalized experiences using
          QR codes, NFC, and contextual messaging.
        </Typography>
      </Box>

      {/* Who, What, Where, When, How Section */}
      <Grid container spacing={4} className={classes.section}>
        {/* Who */}
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Grid
              container
              spacing={3}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image="/images/conferencegoers.png" // Replace with actual image
                  alt="Who Image"
                  className={classes.media}
                />
              </Grid>
              <Grid item xs={5}>
                <CardContent className={classes.content}>
                  <Typography variant="h2">Who</Typography>
                  <Typography variant="subtitle1">
                    Conference organizers, event planners, and attendees looking
                    for seamless engagement and personalized experiences.
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* What */}
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Grid
              container
              spacing={3}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={5}>
                <CardContent className={classes.content}>
                  <Typography variant="h2">What</Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.description}
                  >
                    A platform that delivers schedules, speaker presentations,
                    and personalized content through LifeCodes and contextual
                    messaging.
                  </Typography>
                </CardContent>
              </Grid>

              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image="/images/attendeeinfo.png" // Replace with actual image
                  alt="What Image"
                  className={classes.media}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* Where */}
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Grid
              container
              spacing={3}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image="/images/conferencerooms.png" // Replace with actual image
                  alt="Where Image"
                  className={classes.media}
                />
              </Grid>

              <Grid item xs={5}>
                <CardContent className={classes.content}>
                  <Typography variant="h2">Where</Typography>
                  <Typography variant="subtitle1">
                    Any conference or event venue, from convention centers to
                    virtual spaces, where engagement and efficiency are key.
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* When */}
        <Grid container item xs={12}>
          <Card className={classes.card}>
            <Grid
              container
              spacing={3}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={5}>
                <CardContent className={classes.content}>
                  <Typography variant="h2">When</Typography>
                  <Typography variant="subtitle1">
                    From pre-event planning to real-time attendee interactions
                    and post-event follow-ups, LifeTagger is with you every step
                    of the way.
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image="/images/conferencechecklist.png" // Replace with actual image
                  alt="When Image"
                  className={classes.media}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>

        {/* How */}
        <Grid item xs={12}>
          <Card className={classes.card}>
            <Grid
              container
              spacing={3}
              sx={{
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={6}>
                <CardMedia
                  component="img"
                  image="https://via.placeholder.com/150" // Replace with actual image
                  alt="How Image"
                  className={classes.media}
                />
              </Grid>
              <Grid item xs={5}>
                <CardContent className={classes.content}>
                  <Typography variant="h2">How</Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.description}
                  >
                    Use NFC, QR codes, and GPS-powered LifeCodes to deliver
                    dynamic content and streamline event workflows.
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {/* Call to Action */}
      <Box
        sx={{
          textAlign: 'center',
          backgroundColor: '#f5f5f5',
          padding: '40px 20px',
          marginTop: '40px',
          borderRadius: '8px',
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: 'bold', marginBottom: '20px' }}
        >
          Ready to Transform Your Next Event?
        </Typography>
        <Typography variant="h6" sx={{ color: '#666', marginBottom: '20px' }}>
          Discover how LifeTagger can help you create engaging, personalized
          experiences for your attendees.
        </Typography>
        <Typography
          variant="button"
          component="a"
          href="/regspecial"
          sx={{
            backgroundColor: '#1976d2',
            color: '#fff',
            padding: '10px 20px',
            borderRadius: '8px',
            textDecoration: 'none',
            display: 'inline-block',
            marginTop: '10px',
          }}
        >
          Get Started Today
        </Typography>
      </Box>
    </Container>
  );
};

export default ConferenceAndEvents;
