import { connect } from 'react-redux';
import {
  fetchBeacons,
  deleteBeacon,
  createBeacon,
  updateBeacon,
  showBeacon,
  fetchBlueToothBeacons,
  fetchLifeCodes,
} from './beacon.actions';

import Index from './beacon.index';
import New from './beacon.new';
import Edit from './beacon.edit';
import Show from './beacon.show';
import Remove from './beacon.remove';
import CreateQrCode from './qrCode.new';
import QrIndex from './qrCode.index';
import ShowQrCode from './qrCode.show';
import QrCodeDetails from './qrCode.details';
import { fetchTags } from '../tags/tag.actions';

const mapStateToProps = (state) => ({
  beacons: state.beacon.data,
  blueBeacons: state.beacon.data,
  tags: state.tag.data,
  location: state.history,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  all: () => dispatch(fetchBeacons()),
  remove: (query) => {
    query.index = ownProps.index;
    dispatch(deleteBeacon({ query }));
  },
  create: (query) => {
    query.index = ownProps.index;
    dispatch(createBeacon({ query }));
  },

  allTags: (payload) => dispatch(fetchTags(payload, 'getTag')),

  update: (query) => dispatch(updateBeacon({ query })),

  show: (query) => dispatch(showBeacon({ query })),

  blueToothBeacons: () => dispatch(fetchBlueToothBeacons()),
  allLifeCodes: (query) => {
    delete query.apiKey;
    dispatch(
      fetchLifeCodes({
        limit: query.limit,
        page: query.page,
        filter: query.filter,
      })
    );
  },

  allBeaconTags: (payload) => dispatch(fetchTags(payload, 'getTags')),
});

const beaconsConnector = connect(mapStateToProps, mapDispatchToProps);

export const All = beaconsConnector(Index);
export const Create = beaconsConnector(New);
export const Update = beaconsConnector(Edit);
export const Get = beaconsConnector(Show);
export const Delete = beaconsConnector(Remove);
export const CreateQr = beaconsConnector(CreateQrCode);
export const IndexQr = beaconsConnector(QrIndex);
export const ShowQr = beaconsConnector(ShowQrCode);
export const QrDetails = beaconsConnector(QrCodeDetails);
