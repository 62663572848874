import React, { Component } from 'react';
import Upload from '../ui/upload.component';
import {
  Typography,
  TextField,
  Card,
  CardContent,
  Box,
  Fab,
  Grid,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import { Flags } from 'react-feature-flags';

let theme = createMuiTheme();
const styles = {
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  margin: {
    margin: theme.spacing.unit * 2,
  },

  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`,
  },

  card: {
    margin: '10px auto',
    width: '95%',
  },

  typography: {
    fontFamily: ['Roboto Condensed'].join(','),
    fontSize: 18,
    body1: {
      fontWeight: 500,
      fontFamily: 'Roboto Condensed',
    },
  },
};

class New extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audio_file: null,
      title: '',
      fileName: '',
      data_type: 'audio',
      description: '',
    };

    this.baseState = {
      audio_file: null,
      title: '',
      fileName: '',
      data_type: 'audio',
      description: '',
    };
  }

  componentDidMount() {}

  handleMediaUpload = (file) => {
    if (file.length && file.length > 0) {
      this.setState({ audio_file: file });
      let files = '';
      for (let i = 0; i < file.length; i++) {
        files = `${file[i].name},`;
        this.setState({ fileName: files });
      }
    }
  };

  setMessageText = (event) => {
    this.setState({ title: event.target.value });
  };

  setDescriptionText = (event) => {
    this.setState({ description: event.target.value });
  };

  handleSubmit = () => {
    this.props.create(this.state);
    this.resetForm();
  };

  resetForm = () => {
    this.setState(this.baseState);
  };

  render() {
    const mediaTypes = [
      'video/mp4',
      'video/quicktime',
      'audio/mpeg',
      'audio/mp4',
      'audio/mp3',
      'audio/x-aiff',
      'audio/ogg',
      'audio/vorbis',
    ];

    return (
      <Grid item sm={6}>
        <Box>
          <Flags
            authorizedFlags={['pro']}
            renderOn={() => (
              <Typography>
                <h3>Upload Media</h3>
              </Typography>
            )}
            renderOff={() => (
              <Typography>
                <h3>Upload Media</h3>
                <Fab
                  size="small"
                  color="secondary"
                  variant="extended"
                  disabled={false}
                >
                  Pro Feature
                </Fab>
              </Typography>
            )}
          ></Flags>
          <Flags authorizedFlags={['pro', 'beta', 'lite', 'premium']}>
            <Card>
              <CardContent>
                <Typography varient="subtitle1">
                  <Box fontWeight="400" color={grey[900]}>
                    Title
                  </Box>
                </Typography>
                <Box>
                  <TextField
                    fullWidth
                    defaultValue="Show Title"
                    value={this.state.title}
                    margin="normal"
                    onChange={this.setMessageText.bind(this)}
                  />
                </Box>

                <Typography varient="subtitle1">
                  <Box fontWeight="400" color={grey[900]}>
                    Description
                  </Box>
                </Typography>

                <Box mb={3}>
                  <TextField
                    fullWidth
                    defaultValue="Description"
                    value={this.state.description}
                    margin="normal"
                    onChange={this.setDescriptionText.bind(this)}
                  />
                </Box>

                <Card>
                  <CardContent>
                    <Upload
                      handleFileUpload={this.handleMediaUpload}
                      mediaTypes={mediaTypes}
                    />
                  </CardContent>
                </Card>
                <Typography fontFamily="Roboto Condensed" fontWeight="400">
                  {this.state.fileName}
                </Typography>
              </CardContent>
              <Box m={3}>
                <Fab
                  size="small"
                  color="secondary"
                  variant="extended"
                  disabled={!this.state.fileName || !this.state.title}
                  onClick={() => this.handleSubmit()}
                >
                  Upload Media{' '}
                  <i className="material-icons">keyboard_arrow_right</i>
                </Fab>
                <div>
                  (Note: Max file size is 500mb. Media uploads take a few
                  minutes to complete. Your new media will appear shortly.)
                </div>
              </Box>
            </Card>
          </Flags>
        </Box>
      </Grid>
    );
  }
}

export default withStyles(styles)(New);
